import './common';
import './search';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

if (module.hot) {
    module.hot.accept();
}
// my comment
(() => {
    if (document.body.querySelector('math')
        || document.body.textContent.match(/(?:\$|\\\(|\\\[|\\begin\{.*?})/)) {
        if (!window.MathJax) {
            window.MathJax = {
                tex: {
                    inlineMath: {
                        '[+]': [
                            ['$', '$'],
                        ],
                    },
                },
            };
        }
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
        document.head.appendChild(script);
    }
})();
