const $ = require('jquery');

window.common = {};

common.get_filename = function() {
    const split = window.location.href.split('/');
    let fileName = split[split.length - 1];
    fileName = common.prettify_text(fileName);
    return fileName;
};

common.get_headline = function() {
    return common.get_filename().replace('_', ' ');
};

common.get_title = function() {
    const split = common.get_filename().split('/');
    return split[split.length - 1].replace('_', ' ');
};

common.set_title = function() {
    document.getElementsByTagName('title')[0].innerHTML = common.get_title();
};

common.prettify_text = function(text) {
    return text.replace(/_/g, ' ').replace('.html?o=0', '').replace('.html', '').replace(/#[A-Z]$/, '');
};

common.get_sub_path = function(text) {
    if (text === 'Informatik') {
        return `${__DOMAIN_ROOT__}/`;
    }
    const path = window.location.href;
    return path.slice(0, path.indexOf(text) + text.length);
};

common.populate_fields = function() {
    const headline = $('#headline');
    if (headline[0] && headline[0].innerText === '') {
        const text = common.get_headline();
        headline.text(text);
    }
    const breadcrumb = $('#ovk-breadcrumb');
    const path = window.location.href;

    const start = '/Informatik/';
    const index = path.indexOf(start);

    if (index !== -1) {
        const breadcrumbArray = path.substr(index + start.length).split('/');
        const list = document.createElement('ul');
        list.className = 'ovk-breadcrumb';

        breadcrumb.append(list);

        breadcrumbArray.forEach((element) => {
            const elem = document.createElement('li');
            const link = document.createElement('a');
            link.href = common.get_sub_path(element);
            link.innerText = common.prettify_text(element);
            elem.append(common.prettify_text(element));
            list.append(elem);
        });
    }
};

// https://www.w3schools.com/howto/howto_js_dropdown_sidenav.asp
common.set_navigation = function() {
    /* Loop through all dropdown buttons to toggle between hiding and showing its dropdown content
        This allows the user to have multiple dropdowns without any conflict */
    const dropdown = document.getElementsByClassName('dropdown-btn');
    let i;

    for (i = 0; i < dropdown.length; i += 1) {
        dropdown[i].addEventListener('click', function() {
            this.classList.toggle('active');
            const dropdownContent = this.nextElementSibling;
            if (dropdownContent.style.display === 'block') {
                dropdownContent.style.display = 'none';
            } else {
                dropdownContent.style.display = 'block';
            }
        });
    }
};

// function being called on each page
common.init = function() {
    $(
        () => {
            document.getElementById('home-icon').style.display = 'block';
            document.getElementById('hamburger-container').style.display = 'block';
        },
    );
};

$('#hamburger-menu').click(() => {
    $(".dropdown-content").toggleClass("show");
});


$(document).click(function(e) {           
    if(!$(e.target).hasClass('dropdown-icon') && !$(e.target).parent().hasClass('dropdown-icon')){
        if ($("#dropdown-content").hasClass('show')) {
            $("#dropdown-content").removeClass('show');
        }     
    }
}); 

common.init();
