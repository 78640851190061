// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./dropdown.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./filter.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://dditools.inf.tu-dresden.de/ovk/style/main.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://unpkg.com/multiple-select@1.5.2/dist/multiple-select.min.css);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".center{margin-left:auto;margin-right:auto}.margin{margin-bottom:3em}.ovk-bullet-list>li{list-style-type:none}.list-game-link>img{border:1px solid black;margin:0 1em 0 0}.list-game-link>img:hover{cursor:pointer;color:black;opacity:0.4}.list-game-link:hover img{cursor:pointer;color:black;opacity:0.4}.game-list-element{margin-bottom:1em}body{display:visible}#ovk-breadcrumb{min-height:3.5em}.header img{position:relative;left:0.9em;height:auto;width:230px}.header h1{position:relative;left:2.5em}.header{display:flex;align-items:baseline}\n", "",{"version":3,"sources":["webpack://./src/style/overview.scss"],"names":[],"mappings":"AAEA,QACI,gBAAiB,CACjB,iBAAkB,CACrB,QAGC,iBAAkB,CACnB,oBAIG,oBAAqB,CACxB,oBAKG,sBAAuB,CACvB,gBAAiB,CACpB,0BAGG,cAAe,CACf,WAAY,CACZ,WAAY,CACf,0BAIO,cAAe,CACf,WAAY,CACZ,WAAY,CACf,mBAID,iBAAkB,CACrB,KAGG,eAAgB,CACnB,gBAGG,gBAAiB,CACpB,YAGG,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,WAAY,CACf,WAGG,iBAAkB,CAClB,UAAW,CACd,QAGG,YAAa,CACb,oBAAqB","sourcesContent":["/* additionally style for headline */\n\n.center {\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.margin {\n  margin-bottom: 3em;\n}\n\n/* Remove enumeration element */\n.ovk-bullet-list > li {\n    list-style-type: none;\n}\n\n/* Style of the overview */\n\n.list-game-link > img {\n    border: 1px solid black;\n    margin: 0 1em 0 0;\n}\n\n.list-game-link > img:hover {\n    cursor: pointer;\n    color: black;\n    opacity: 0.4;\n}\n\n.list-game-link:hover {\n    img {\n        cursor: pointer;\n        color: black;\n        opacity: 0.4;\n    }\n}\n\n.game-list-element {\n    margin-bottom: 1em;\n}\n\nbody {\n    display: visible;\n}\n\n#ovk-breadcrumb {\n    min-height: 3.5em;\n}\n\n.header img  {\n    position: relative;\n    left: 0.9em;\n    height: auto;\n    width: 230px;\n}\n  \n.header h1 {\n    position: relative;\n    left: 2.5em;\n}\n\n.header {\n    display: flex;\n    align-items: baseline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
