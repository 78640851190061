const $ = require('jquery');
require('jquery-ui');
require('multiple-select');

$(document).ready(function(){
    
    let selectedProjects = [];
    const urlParams = new URLSearchParams(window.location.search);
    const validContentSections = ["AUD", "DB","FS","RN"];
    const validTargetGroups = ["S1","S2","GS","UNI"];
    const validTypes = ["IV","LA","LS","VD"];
    const validProjects = ["OVK", "SY", "VR"];
    $('#reset-button').prop("disabled",true);
    $('.ovk-bullet-list li').each(function(){
        $(this).addClass('filtered');
    });

    $('.select').each(function(){
        const placeholder = $(this).data('placeholder');
        const maxSelects = $(this).children().length;
        
        $(this).multipleSelect({
            placeholder: placeholder,
            minimumCountSelected: 0,
            formatSelectAll () {
                return '[Alle auswählen]'
            },
            formatAllSelected () {
                return placeholder + " (" + maxSelects + ')'
    
            },
            formatNoMatchesFound () {
                return 'Keine Ergebnisse gefunden'
            },
            formatCountSelected (count) {
                return placeholder + " (" + count + ')'
            },
            onClick: function() {
                filterSelectedValues();
            },
            onCheckAll: function() {
                filterSelectedValues();
            },
            onUncheckAll: function() {
                filterSelectedValues();
            }
        });
    });

    $("#reset-button").click(function(){
        $('select').multipleSelect('uncheckAll');
        filterSelectedValues();
    }); 

    const selectedContentSections = getValidParameters(validContentSections,"contentSections");
    const selectedTargetGroups = getValidParameters(validTargetGroups,"targetGroups");
    const selectedTypes = getValidParameters(validTypes,"types");    
    const selectedParameters = selectedContentSections.concat(selectedTargetGroups, selectedTypes);
    $('select').multipleSelect('setSelects', selectedParameters);
    selectedProjects = getValidParameters(validProjects,"projects");
    filterSelectedValues();

    function filterSelectedValues() {
        const selectedValues = [];
        selectedValues.push($('#content-section-select').multipleSelect('getSelects'));
        selectedValues.push($('#target-group-select').multipleSelect('getSelects'));
        selectedValues.push($('#type-select').multipleSelect('getSelects'));
        selectedValues.push(selectedProjects);
        const isEmpty = a => Array.isArray(a) && a.every(isEmpty);
        $('.ovk-bullet-list li').each(function(){
            if(isEmpty(selectedValues)){
                $(this).css('display', "");
                $(this).parent().prev('h2').css('display', '');
                $('#reset-button').prop("disabled",true);
                $(this).addClass('filtered');
            }else{
                const hasContentSection = filterAttribute($(this), selectedValues[0], "content-section");
                const hasTargetGroup = filterAttribute($(this), selectedValues[1], "target-group");
                const hasType = filterAttribute($(this), selectedValues[2], "type");
                const hasProject = filterAttribute($(this), selectedValues[3], "project");
                display = hasContentSection && hasTargetGroup && hasType && hasProject ? "" : "none";
                $(this).css('display', display);
                $(this).parent().prev('h2').css('display', '');
                if(display === 'none'){
                    $(this).removeClass('filtered');
                    if(!$(this).siblings(':visible').length){
                        $(this).parent().prev('h2').css('display', display);
                    }
                }else{
                    $(this).addClass('filtered');
                }
                $('#reset-button').prop("disabled",false);
            }
        });
        $('#no-elements').remove();
        if($('.ovk-bullet-list li:hidden').length == $('.ovk-bullet-list li').length){
            $('#select-container').after('<p style="margin-top: 1.5rem;" id="no-elements">Für diese Auswahl wurden keine Ergebnisse gefunden.</p>');
        }
    }

    function filterAttribute(element, array, attribute){
        if(array.length == 0){
            return true;
        }else{
            return array.some(r=> element.children("div").data(attribute).split(",").includes(r));
        }
    }
    
    function getValidParameters(validParameterArray, parameterName){
        const validParameters = [];
        const parameters = urlParams.has(parameterName) ? urlParams.get(parameterName).split(',') : [];
        parameters.forEach(function (item) {
            if(validParameterArray.includes(item)){
                validParameters.push(item);
            }
        });
        return validParameters
    }

    $('#input').on('input', function(){
        const valueInput = $('#input').val().toLowerCase().trim();
        $('.ovk-bullet-list li.filtered').each(function(i,obj){
            const valueLi = $(this).text().toLowerCase().trim();
            const display = valueLi.search(new RegExp(valueInput.replace(/\s+/, '|'))) != -1 ? '' : 'none';
            $(this).css('display', display);
            $(this).parent().prev('h2').css('display', '');
            if(display === 'none'){
                if(!$(this).siblings(':visible').length){
                    $(this).parent().prev('h2').css('display', display);
                }
            }
        });
    });
});
